import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import { TextLight } from "../../../base/BaseText";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseTable, {
  BaseTR,
  BaseTD,
  BaseTH,
} from "../../../base/BaseTable/BaseTable";
import ConfirmIcon from "../../../images/icon-confirm.svg";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import {
  confirmRequestDetailsSubmission,
  cancelRepairDetailsConfirmation,
} from "../state/operators/repairDetailsOperator";
import { EnumEnquiryModules } from "../../../components/EnquiryOptions/Constants";
import { getFormmatedDate, getFormatedTime } from "../../../helpers/dateUtils";
import { INCIDENT_PATH_TYPE } from "../../../helpers/constants";
import { isEmpty } from "../../../helpers/formatUtils";
import { PROGRESS_DATA } from "../../../helpers/constants";

const ConfirmRepairDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );
  
  const isCancelRequest = enquiryOption === EnumEnquiryModules.CancelRequest;
  const isChangeSchedule = enquiryOption === EnumEnquiryModules.ChangeSchedule;

  const incidentPathType = useSelector((state) => state.serviceRequest.type);

  const determinDetails = useSelector(
    (state) => state.serviceRequest.determineIncidentDetails
  );
  const isNoActionRequired =
    incidentPathType === INCIDENT_PATH_TYPE.NO_ACTION_REQUIRED;
  let determinePickupSlot = "";
  if (isNoActionRequired) {
    const slotH = determinDetails.DeliveryInstructions.split("|")[0];
    determinePickupSlot =
      slotH === "16H"
        ? "12pm - 4pm"
        : slotH === "12H"
          ? "9am - 12pm"
          : slotH === "14H"
            ? determinDetails.ShippingMethodType === "Same Day"
              ? "12pm - 2pm"
              : "10am - 2pm"
            : "";
  }

  const scheduleData = useSelector((state) => state.claim.schedule);

  const repairOption = useSelector((state) =>
    isNoActionRequired || isCancelRequest || isChangeSchedule
      ? state.serviceRequest.determineIncidentDetails.RepairRequest
          .RepairRequestType
      : state.journeyMessages.repairOption
  );
  const isWalkIn = repairOption === "WALKIN";

  const walkInData = useSelector((state) => state.claim.walkIn);

  const pickupAddress = isNoActionRequired
    ? determinDetails.ShippingAddress
    : scheduleData.selectedPickupAddress;

  const pickupDate = isNoActionRequired
    ? determinDetails.ExpectedDeliveyDate
    : scheduleData.selectedPickupDate;

  const pickupSlot = isNoActionRequired
    ? determinePickupSlot
    : scheduleData.selectedPickupSlot;

  const customerCaseNumber = useSelector(
    (state) => enquiryOption === EnumEnquiryModules.CancelRequest ? state.serviceRequest.determineIncidentDetails.CustomerCaseNumber : state.serviceRequest.serviceRequestDetails.CustomerCaseNumber
  );
  const srFee = useSelector(
    (state) => enquiryOption === EnumEnquiryModules.CancelRequest ? state.serviceRequest.determineIncidentDetails.ServiceFee : state.claim.payment.serviceFee.TotalAmountWithDiscount || state.claim.payment.serviceFee.TotalAmount
  );

  const getAddressText = (address) => {
    const { Address1, Address2, Address3, City, PostalCode } = address;
    return `${Address1} ${Address2} ${Address3} ${City} ${PostalCode}`;
  };

  const repairCenterLocation = isWalkIn ?
    isNoActionRequired || isCancelRequest || isChangeSchedule
      ? `${
          determinDetails.RepairRequest.ServiceProviderName
        }, ${getAddressText(determinDetails.ShippingAddress)}`
      : walkInData && walkInData.selectedStore
      ? `${walkInData.selectedStore.SERVICE_PROVIDER_NAME}, 
      ${walkInData.selectedStore.ADDRESS_LINE_1} ${walkInData.selectedStore.ADDRESS_LINE_2 || ""} ${walkInData.selectedStore.ADDRESS_LINE_3 || ""} ${walkInData.selectedStore.CITY_NAME} ${walkInData.selectedStore.POSTAL_CODE} ${walkInData.selectedStore.STATE_PROVINCE_NAME}`
      : "" : "";

  const appointmentDateTime = isWalkIn ?
    isNoActionRequired || isCancelRequest || isChangeSchedule
      ? `${getFormmatedDate(
          determinDetails.RepairRequest.AppointmentDate,
          "d MMM yyyy"
        )}, ${getFormatedTime(
          determinDetails.RepairRequest.AppointmentDate,
          true,
          "hh mm A"
        )}`
      : walkInData && walkInData.selectedAppointmentDate
      ? `${getFormmatedDate(
          walkInData.selectedAppointmentDate,
          "d MMM yyyy"
        )} ${walkInData.selectedAppointmentSlot}`
      : "" : "";

  const tableHeaders = [
    { key: "srNo", text: "Screen Repair Request No.", hideForNoAction: false },
    // { key: "pickupAddress", text: "Pick-up Address", hideForNoAction: false },
    // { key: "returnAddress", text: "Return Address", hideForNoAction: true },
    // {
    //   key: "pickupDateTime",
    //   text: "Pick-up date / time",
    //   hideForNoAction: false,
    // },
    // {
    //   key: "returnDateTime",
    //   text: "Return date / time",
    //   hideForNoAction: true,
    // },
    {
      key: "requestType",
      text: t("ConfirmAddress.LabelforRequestType"),
      hideForNoAction: false,
    },
    {
      key: "pickupAddress",
      text: "Pick-up Address",
      hideForNoAction: isWalkIn
          ? true
          : false,
    },
    {
      key: "returnAddress",
      text: "Return Address",
      hideForNoAction:
        isWalkIn
          ? true
          : false,
    },
    {
      key: "pickupDateTime",
      text: "Pick-up date / time",
      hideForNoAction: isWalkIn
        ? true
        : false,
    },
    {
      key: "returnDateTime",
      text: "Return date / time",
      hideForNoAction:
      isWalkIn
          ? true
          : false,
    },
    {
      key: "repairCenterLocation",
      text: t("ConfirmAddress.LabelforRepairCenterLocation"),
      hideForNoAction: isWalkIn
        ? false
        : true,
    },
    {
      key: "appointmentDateTime",
      text: t("ConfirmAddress.LabelforAppointmentDateTime"),
      hideForNoAction: isWalkIn
        ? false
        : true,
    },
    {
      key: "srFee",
      text: "Screen Repair Fee",
      hideForNoAction: isEmpty(srFee) ? true : false,
    },
  ];

  const tableData = [
    {
      srNo: customerCaseNumber,
      // pickupAddress: getAddressText(pickupAddress),
      // returnAddress: getAddressText(scheduleData.selectedDeliveryAddress),
      // pickupDateTime: `${getFormmatedDate(
      //   pickupDate,
      //   "d MMM yyyy"
      // )} ${pickupSlot}`,
      // returnDateTime: `${getFormmatedDate(
      //   scheduleData.selectedDeliveryDate,
      //   "d MMM yyyy"
      // )} ${scheduleData.selectedDeliverySlot}`,
      pickupAddress: pickupAddress ? getAddressText(pickupAddress) : "",
      returnAddress: scheduleData.selectedDeliveryAddress ? getAddressText(scheduleData.selectedDeliveryAddress) : "",
      pickupDateTime: pickupDate
        ? `${getFormmatedDate(pickupDate, "d MMM yyyy")} ${pickupSlot}`
        : "",
      returnDateTime: scheduleData.selectedDeliveryDate
      ? `${getFormmatedDate(scheduleData.selectedDeliveryDate, "d MMM yyyy")} ${scheduleData.selectedDeliverySlot}`
      : "",
      requestType: repairOption === "PUR" ? PROGRESS_DATA.PUR.title : PROGRESS_DATA.WALK_IN.title,
      repairCenterLocation: repairCenterLocation,
      appointmentDateTime: appointmentDateTime,
      srFee: `SGD ${srFee} (inclusive of GST)`,
    },
  ];

  const handleSubmit = () => {
    setDisabled(true);
    dispatch(confirmRequestDetailsSubmission());
  };

  const handleCancelClick = () => {
    setDisabled(true);
    dispatch(cancelRepairDetailsConfirmation());
  };

  return (
    <BaseCard>
      <BaseIcon icon={ConfirmIcon} name="confirm" center />
      <BaseLabel>
        <Trans i18nKey={`ConfirmRepairDetails.${enquiryOption}.Title`}>
          <TextLight>
            Please confirm the
            <br />
            Screen Repair Request cancellation
          </TextLight>
        </Trans>
      </BaseLabel>
      <BaseTable
        title={t(`ConfirmRepairDetails.${enquiryOption}.TableTitle`)}
        verticle
      >
        {tableHeaders.map((header) => {
          if (
            // (isNoActionRequired && header.hideForNoAction) ||
            // (header.key === "srFee" && header.hideForNoAction)
            header.hideForNoAction
          ) {
            return;
          }
          return (
            <BaseTR key={header.key}>
              <BaseTH>{header.text}</BaseTH>
              {tableData.map((data, i) => (
                <BaseTD key={`${header.key}_${i}`}>{data[header.key]}</BaseTD>
              ))}
            </BaseTR>
          );
        })}
      </BaseTable>
      <BaseButtonGroup>
        {[
          EnumEnquiryModules.NewRequest,
          EnumEnquiryModules.InWarrantyRequest,
          EnumEnquiryModules.ResumeRequest,
        ].includes(enquiryOption) && (
          <BaseButton
            text={t("CancelButton")}
            onClick={handleCancelClick}
            disabled={disabled}
            className="border-app-border focus:bg-app-border hover:bg-app-border"
          />
        )}
        <BaseButton
          className="px-10"
          text={t(`ConfirmRepairDetails.${enquiryOption}.Button`)}
          onClick={handleSubmit}
          disabled={disabled}
          clicked={disabled}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmRepairDetails;
